/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//
export default {
  'browser-storage-controls.consent-banner-modal.analytical.description.082624':
    'Measures usage to improve product performance',
  'browser-storage-controls.consent-banner-modal.analytical.option.082624': 'Allow analytical cookies',
  'browser-storage-controls.consent-banner-modal.cancel.button': 'Cancel',
  'browser-storage-controls.consent-banner-modal.confirm.button': 'Confirm',
  'browser-storage-controls.consent-banner-modal.description':
    "We respect your right to privacy, so you can select which cookies you allow. However, <bold>strictly necessary</bold> cookies are always allowed as they're essential for our products to function. They don't store any personally identifiable information or track your browsing habits. {link}",
  'browser-storage-controls.consent-banner-modal.functional.description':
    "These cookies enable our product to provide enhanced functionality and personalization. They may be set by us or by third party providers whose services we've added to our pages. If you don't allow these cookies, some or all of these services may not function properly.",
  'browser-storage-controls.consent-banner-modal.functional.description.082624':
    'Provides enhanced functionality and personalization.',
  'browser-storage-controls.consent-banner-modal.functional.option': 'Allow functional cookies',
  'browser-storage-controls.consent-banner-modal.functional.option.082624': 'Allow functional cookies',
  'browser-storage-controls.consent-banner-modal.marketing.description.082624': 'Used for targeted advertising.',
  'browser-storage-controls.consent-banner-modal.marketing.option.082624': 'Allow marketing cookies',
  'browser-storage-controls.consent-banner-modal.performance.description':
    "These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our product. All the information these cookies collect is aggregated and therefore anonymous. If you don't allow these cookies, we won't know when you've visited our product and won't be able to monitor its performance.",
  'browser-storage-controls.consent-banner-modal.performance.option': 'Allow performance cookies',
  'browser-storage-controls.consent-banner-modal.strict.description.082624':
    "Atlassian tracks <bold>strictly necessary</bold> cookies that are essential for our products to function, so you can't adjust these. These cookies don't store personally identifiable information or track browsing habits.",
  'browser-storage-controls.consent-banner-modal.summary.description.082624':
    'Because we value your privacy, you can select which cookies you allow. {link}',
  'browser-storage-controls.consent-banner-modal.targeting.description':
    "These cookies may be set through our product by our partners. They don't store directly personal information, but are based on identifying your unique browser and internet device. If you don't allow these cookies, you'll experience less targeted advertising.",
  'browser-storage-controls.consent-banner-modal.targeting.option': 'Allow targeting cookies',
  'browser-storage-controls.consent-banner-modal.title': 'Cookie preferences',
  'browser-storage-controls.consent-banner-modal.tracking.link': 'Atlassian cookies and Tracking notice',
  'browser-storage-controls.consent-banner-modal.tracking.link.082624': 'Review our cookies and tracking notice',
  'browser-storage-controls.consent-banner.accept-all.button': 'Accept all',
  'browser-storage-controls.consent-banner.cookies-description.text':
    'Atlassian uses cookies to improve your browsing experience, perform analytics and research, and conduct advertising. Accept all cookies to indicate that you agree to our use of cookies on your device. {linkToCookiesTrackingNotice}',
  'browser-storage-controls.consent-banner.cookies-tracking-notice.text': 'Atlassian cookies and Tracking notice',
  'browser-storage-controls.consent-banner.error-flag.description': 'Try again in a few minutes.',
  'browser-storage-controls.consent-banner.error-flag.title': "We're unable to update the policy",
  'browser-storage-controls.consent-banner.only-necessary.button': 'Only necessary',
  'browser-storage-controls.consent-banner.preferences.button': 'Preferences',
  'browser-storage-controls.consent-banner.success-flag.description':
    'You can change this at any time in your Atlassian account.',
  'browser-storage-controls.consent-banner.success-flag.description-local-only':
    'Your preferences will only affect this site.',
  'browser-storage-controls.consent-banner.success-flag.title': 'Cookie preferences saved',
};
